import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { Return } from 'images/Goal';
import { Footer } from '../Landing/Footer';

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon?: string;
  setStepNumber: () => void;
  stepNumber: number;
  title: string;
  secondTitle?: string;
  children: React.ReactNode;
}

export const LayoutDesktop: React.FC<LayoutProps> = ({
  className,
  styleFooter,
  icon,
  children,
  setStepNumber,
  stepNumber,
}) => {
  const [isProfile, setProfile] = useState<string>();
  const classes = useStyles();

  const handleClick = () => {
    if (stepNumber === 0) {
      navigate(Route.investments + '/?#objectives');
    }
    if (stepNumber === 1) {
      setStepNumber(0);
    }
    if (stepNumber === 2) {
      navigate(Route.investments + '/?#objectives');
    }
  };

  return (
    <div className={`${className || ''} ${classes.container}`}>
      <div className={classes.subContainer}>
        <section>
          <div className={classes.headerLayout}>
            <div className={classes.IconMision}>
              <img src={icon} />
            </div>
            <div onClick={handleClick} className={classes.return}>
              <img src={Return} />
              Volver
            </div>
          </div>
        </section>
        {children}
      </div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  subContainer: {
    display: 'flex',
    marginBottom: '10rem',
  },
  container: {
    overflowX: 'hidden',
    background: '#F4F3F3',
  },
  children: {},
  headerLayout: {
    display: 'grid',
    gap: '2rem',
    alignItems: 'center',
    paddingLeft: 106,
    paddingTop: 50,
    width: 200,
    justifyContent: 'center',
    '& img': {
      width: 140,
      height: 50,
    },
  },
  txts: {
    paddingTop: '1rem',
    '& span': {
      display: 'block',
    },
  },
  numberMissionContainer: {
    position: 'relative',
  },
  IconMision: {
    '& img': {
      width: 70,
      height: 70,
    },
  },
  containerData: {
    display: 'flex',
    gap: '2rem',
    marginLeft: '3rem',
    width: '100%',
    '& > div': {
      cursor: 'pointer',
      width: '100%',
    },
  },
  return: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    '& img': {
      width: 50,
      height: 50,
    },
  },
  headerLayout2: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    paddingTop: 20,
    marginBottom: '2rem',
    '& img': {
      width: 140,
      height: 140,
    },
  },
  container2: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '40% 40%',
    gridTemplateAreas: "'goal plazo' 'react meta' 'found buttons'",
    '& input': {
      width: 400,
    },
  },
  goal: {
    gridArea: 'goal',
    '&>div': {
      width: 428,
    },
  },
  titleReact: {
    gridArea: 'react',
  },
  plazo: {
    gridArea: 'plazo',
  },
  meta: {
    gridArea: 'meta',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#393e44',
    display: 'block',
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    display: 'block',
    color: theme.palette.text.secondary,
  },
  buttons: {
    gridArea: 'buttons',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    paddingTop: '4rem',
    width: 430,
    '& .outlined, .primary': {
      width: 185,
    },
    '& .primary': {
      marginLeft: 20,
    },
  },
  found: {
    gridArea: 'found',
  },
  investPeriod: {
    maxWidth: 428,
  },
  foundSelector: {
    width: 428,
  },
}));
