import React, { useState, useContext, useEffect } from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { LayoutGoal, SEO } from "@components";
import { Create } from "images/Goal";
import { useAuth } from "@hooks";
import { CreateGoalMobile } from "@components/Structural/Goal/CreateGoalMobile";
import { CreateGoalDesktop } from "@components/Structural/Goal/CreateGoal";
import CreateGoalStep1 from "@components/Structural/Goal/CreateGoalStep1";
import CreateGoalStep2 from "@components/Structural/Goal/CreateGoalStep2";
import { LayoutDesktop } from "@components/Structural/Goal/LayoutDesktop2";
import { LayoutMobile } from "@components/Structural/Goal/LayoutMobile";
import { createGoal } from "@apollo";
import { GoalsContext } from "@context";

const CreateGoal: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const [stepNumber, setStepNumber] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0); //period!!
  const [goalValue, setGoalValue] = useState<number>(0);
  const [goalName, setGoalName] = useState<string>(undefined);
  const [investmentFrequency, setInvestmentFrequency] =
    useState<string>(undefined);
  const { addGoal, updateGoal } = useContext(GoalsContext);
  const [goalType, setGoalType] = useState<string>(undefined);
  const [goalMonthlyInv, setGoalMonthlyInv] = useState<number>(0);
  const [depositValue, setDepositValue] = useState<number>(0);
  const [firstFundSelection, setFirstFundSelection] =
    useState<string>(undefined);

  useEffect(() => {
    setStepNumber(0);
  }, []);

  useEffect(() => {
    if (stepNumber === 2) {
      if (goalName && goalValue) {
        addGoal({
          name: goalName,
          goal: goalValue,
          period: sliderValue,
          investmentFrequency: investmentFrequency,
          type: goalType,
          monthlyInvestment: goalMonthlyInv,
        });
      }
    }
  }, [stepNumber]);

  useEffect(() => {
    if (sliderValue < 1) {
      setFirstFundSelection("Conservador");
    }
    if (sliderValue >= 1 && sliderValue < 5) {
      setFirstFundSelection("Moderado");
    }
    if (sliderValue > 4) {
      setFirstFundSelection("Agresivo");
    }
  }, [sliderValue]);

  return (
    <>
      <SEO />
      {!isMobile && (
        <>
          <LayoutDesktop
            icon={Create}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
          >
            {stepNumber === 0 && (
              <CreateGoalDesktop
                title="Crear un objetivo nuevo"
                icon={Create}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                goalName={goalName}
                setGoalName={setGoalName}
                setStepNumber={setStepNumber}
                setGoalType={setGoalType}
              />
            )}
            {stepNumber === 1 && (
              <CreateGoalStep1
                icon={Create}
                title={"Paso 2"}
                setStepNumber={setStepNumber}
                setGoalValue={setGoalValue}
                goalValue={goalValue}
                setGoalMonthlyInv={setGoalMonthlyInv}
                goalMonthlyInv={goalMonthlyInv}
                setDepositValue={setDepositValue}
                sliderValue={sliderValue}
                depositValue={depositValue}
                setFirstFundSelection={setFirstFundSelection}
                firstFundSelection={firstFundSelection}
              />
            )}
            {stepNumber === 2 && (
              <CreateGoalStep2
                nameGoal={goalName}
                term={sliderValue}
                goal={goalValue}
                depositValue={depositValue}
                firstFundSelection={firstFundSelection}
              />
            )}
          </LayoutDesktop>
        </>
      )}
      {isMobile && (
        <>
          <LayoutMobile
            icon={Create}
            pageName={"Crear Objetivo"}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
          >
            {stepNumber === 0 && (
              <CreateGoalMobile
                title="Crear un objetivo nuevo"
                icon={Create}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                goalName={goalName}
                setGoalName={setGoalName}
                setStepNumber={setStepNumber}
                setGoalType={setGoalType}
              />
            )}
            {stepNumber === 1 && (
              <CreateGoalStep1
                icon={Create}
                title={"Paso 2"}
                setStepNumber={setStepNumber}
                setGoalValue={setGoalValue}
                goalValue={goalValue}
                setGoalMonthlyInv={setGoalMonthlyInv}
                goalMonthlyInv={goalMonthlyInv}
                setDepositValue={setDepositValue}
                sliderValue={sliderValue}
                depositValue={depositValue}
                setFirstFundSelection={setFirstFundSelection}
                firstFundSelection={firstFundSelection}
              />
            )}
            {stepNumber === 2 && (
              <CreateGoalStep2
                nameGoal={goalName}
                term={sliderValue}
                goal={goalValue}
                depositValue={depositValue}
                firstFundSelection={firstFundSelection}
              />
            )}
          </LayoutMobile>
        </>
      )}
    </>
  );
};

export default CreateGoal;

const useStyles = makeStyles((theme) => ({
  headerLayout: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    paddingTop: 50,
    marginBottom: "2rem",
    "& img": {
      width: 140,
      height: 140,
    },
  },
  container: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "40% 40%",
    gridTemplateAreas: "'goal plazo' 'react meta'",
    "& input": {
      width: 400,
    },
  },
  goal: {
    gridArea: "goal",
    "&>div": {
      width: 428,
    },
  },
  titleReact: {
    gridArea: "react",
  },
  plazo: {
    gridArea: "plazo",
  },
  meta: {
    gridArea: "meta",
  },
  txts: {
    paddingTop: "1rem",
    "& span": {
      display: "block",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "bold",
    color: "#393e44",
    display: "block",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontWeight: "normal",
    display: "block",
    color:theme.palette.text.secondary,
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
    width: "75%",
    marginTop: 50,
    marginBottom: "5rem",
    "& .outlined, .primary": {
      width: 185,
    },
    "& .primary": {
      marginLeft: 20,
    },
  },
}));
